import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';

/**
 * @description  Buttom
 * @example
 *  <BottomSelf
      onClick={handleCashier}
      loading={loading}
      sx={{
        borderRadius: matchDownMd ? '0 5px 5px 0' : '5px',
        minWidth: matchDownMd ? '40px' : '64px',
        height: matchDownMd ? '40px' : '30px',
        lineHeight: matchDownMd ? '40px' : '30px',
        margin: matchDownMd ? '0' : '0 12px'
      }}
    >
      {!matchDownMobile ? 'CASHIER' : <img src={WALLET} alt="Cashier" />}
    </BottomSelf>
 * @param {string} type theme。
 * @param {string} sx style。
 * @param {boolean} loading loading。
 * @param {fn} onClick onClick
 * @returns {ReactElement} ReactDom。
 */

const themeColor = {
  default: {
    background: '#FFE619',
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    color: '#302B05',
    '&:hover': {
      backgroundColor: '#FFEF6D',
      boxShadow: '0px 0px 10px 0px #FFE936'
    }
  },
  primary: {
    background: 'linear-gradient( 92deg, #36F499 0%, #0AC8BE 100%)',
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    color: '#0D3E2B',
    '&:hover': {
      backgroundColor: '#6dfdf6',
      boxShadow: '0px 0px 10px 0px #6dfdf6'
    }
  },
  commom: {
    background: '#152D43',
    boxShadow: 'inset 0px 0px 2px 0px rgba(255,255,255,0.1)',
    color: '#5584AE',
    '&:hover': {
      // backgroundColor: '#1A1A1A',
      // color:'#fff',
      // boxShadow: 'none'
    }
  }
};

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  border-radius: 5px;
`;
const BottomSelf = (props) => {
  const type = props.type || 'default';
  const disabled = props.disabled || false;
  const theme = ()=>{
    const themeColorObj = {...themeColor[type]}
    disabled && (themeColorObj['&:hover'] = 'none') && (themeColorObj['cursor'] = 'not-allowed') 
    return themeColorObj;
  }
  return (
    <LoadingButton
      onClick={props.onClick}
      loading={props.loading}
      disableRipple
      sx={{
        ...theme(),
        fontSize: '14px',
        borderRadius: '5px',
        minWidth: '64px',
        height: '30px',
        lineHeight: '30px',
        padding: '0 12px',
        fontFamily: `"Geogrotesque Bl", sans-serif`,
        position: 'relative',
        ...props.sx
      }}
    >
      {props.children}
      <Overlay $visible={disabled}></Overlay>
    </LoadingButton>
  );
};

export default BottomSelf;
