import { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';

import './index.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';

const BootAccordion = styled(Accordion)(({ theme }) => ({
  '& .MuiButtonBase-root .Mui-expanded': {
    margin: 0
  },
  '&.Mui-expanded': {
    margin: 0
  },
  '&.MuiPaper-root': {
    // maxWidth: '100%'
    width: '100%',
    boxShadow: 'none'
  }
}));

const SelectSelf = (props) => {
  const { title, list, open, onChange, isCenter, defaultValue } = props;
  const [expanded, setExpanded] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const handleChange = (falg) => {
    setExpanded(falg);
  };

  const handleClickItem = (row) => {
    setCurrentItem(row);
    setExpanded(false);
    onChange && onChange(row.value);
  };

  useEffect(() => {
    setExpanded(open);
  }, [open]);

  useEffect(() => {
    const val = list.find((item) => item.value === defaultValue);
    setCurrentItem(val);
  }, [list, defaultValue]);
  return (
    <div className="Select-self-box">
      <BootAccordion
        expanded={expanded}
        defaultExpanded
        onChange={(e, isExpanded) => handleChange(isExpanded)}
        sx={{}}
        className="Select-self"
      >
        <AccordionSummary
          className={classnames('accordion-title', { 'accordion-active': expanded })}
          sx={{ '& .MuiAccordionSummary-expandIconWrapper': { transitionDuration: '0s' } }}
          expandIcon={
            <ExpandMoreIcon
              sx={{
                width: '18px',
                height: '18px',
                // background: '#00000044',
                color: '#576788',
                fontWeight:600,
                borderRadius: '4px'
              }}
            />
          }
        >
          <Box className={classnames('title-box', { 'title-box-center': isCenter })}>
            {title ? title + ': ' : ''} <span>{currentItem?.label || ''}</span>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="accordion-detail">
          {list &&
            list.map((item) => {
              return (
                <Box
                  key={item.label}
                  className={classnames('accordion-item', {
                    'item-active': currentItem?.label === item.label,
                    'item-center': isCenter
                  })}
                  onClick={() => {
                    handleClickItem(item);
                  }}
                >
                  {/* <div className="mask"></div> */}
                  <div className="content">{item.label}</div>
                </Box>
              );
            })}
        </AccordionDetails>
      </BootAccordion>
    </div>
  );
};

export default SelectSelf;
