import { formatMonth, compareDate, computedCount, isTomorrow, countdownFn, getToday } from 'utils/rewardData';

import { tips, tips_blue, green_icon, red_icon, coin_icon, get_now, Lock_icon, pending } from 'static/images';

import classnames from 'classnames';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Transitions from 'ui-component/extended/Transitions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});
const TodayType = {
  ABLE: 0,
  LOCK: 1,
  NONE: 2
};
const ReceiveType = {
  TIME: 1,
  IMMEDIATELY: 2
};

const CalendarItem = (props) => {
  const { item, today, lockCountdown, todayType, handleReceive, countdown, currentAbleAmount } = props;
  const [rewordList, setRewordList] = useState([]);
  const [dialogRewordList, setDialogRewordList] = useState(false);

  const comoutedAmount = (item) => {
    if (!item) return 0;

    const dailyAmount = item.dailyAmount ? Number(item.dailyAmount) : 0;
    const monthlyAmount = item.monthlyAmount ? Number(item.monthlyAmount) : 0;
    const weeklyAmount = item.weeklyAmount ? Number(item.weeklyAmount) : 0;
    const rebateAmount = item.rebateAmount ? Number(item.rebateAmount) : 0;
    const reBits = item.reBits ? Number(item.reBits) : 0;
    const vipBonus = item.vipBonus ? Number(item.vipBonus) : 0;
    return (dailyAmount + monthlyAmount + weeklyAmount + rebateAmount + vipBonus + reBits).toFixed(2);
  };

  const computedTime = (item) => {
    if (item.timeSlot === 6) return '06:00';
    else if (item.timeSlot === 12) return '12:00';
    else if (item.timeSlot === 18) return '18:00';
    else return '00:00';
  };

  const handleCheckReword = (list) => {
    setRewordList(list);
    setDialogRewordList(true);
  };

  const closeDialogRewordList = () => {
    setDialogRewordList(false);
  };

  const tipsNodes = (row) => {
    return (
      <div className="tips-title">
        <div className="bounsDeails">
          <div className="item">
            <span>Rakeback</span>
            <div>${row.rebateAmount}</div>
          </div>
          <div className="item">
            <span>Daily Bonus</span>
            <div>${row.dailyAmount}</div>
          </div>
          <div className="item">
            <span>Weekly Bonus</span>
            <div>${row.weeklyAmount}</div>
          </div>
          <div className="item">
            <span>Monthly Bonus</span>
            <div>${row.monthlyAmount}</div>
          </div>
          <div className="item">
            <span>Rebits</span>
            <div>${row.reBits}</div>
          </div>
          <div className="item">
            <span>VipBonus</span>
            <div>${row.vipBonus}</div>
          </div>
        </div>
        {/* <span>${comoutedAmount(row)}</span> unlocked at <span>{computedTime(row)}</span>
        {row.state === 1 ? <span className="claimed">Claimed</span> : <span className="unclaimed">Unclaimed</span>} */}
      </div>
    );
  };

  return (
    <div className={classnames('calendar-item ', { 'calendar-active': today === item.dateTime })}>
      <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        <div className="calendar-item-date">{today === item.dateTime ? 'TODAY' : formatMonth(item.dateTime)}</div>
        {compareDate(today, item.dateTime) < 2 ? (
          <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
            {item.recordList && item.recordList.length > 0 && (
              <div className="calendar-content">
                <div className="calendar-amount-val">
                  <span style={{ color: '#FFE619' }}>${item.claimSum} </span>/ ${item.amountSum}
                </div>

                <div className="calendar-icon" onClick={() => handleCheckReword(item.recordList)}>
                  {item.recordList.map((i, index) => (
                    <Tooltip key={index} placement="top" title={() => tipsNodes(i)} color="#152D43" zIndex={9999}>
                      {today === item.dateTime ? (
                        <img src={i.state === 0 ? pending : green_icon} alt="" style={{ cursor: 'pointer' }} />
                      ) : (
                        <img src={i.state === 0 ? red_icon : green_icon} alt="" style={{ cursor: 'pointer' }} />
                      )}
                    </Tooltip>
                  ))}
                </div>
              </div>
            )}
            {/* empty */}
            {item.recordList && item.recordList.length === 0 && (
              <div className="calendar-content">
                <div className="calendar-amount-val">${Number(item.amountSum).toFixed(2)}</div>
                <div className="calender-empty"> No Reward</div>
              </div>
            )}

            {today === item.dateTime && item.recordList && item.recordList.length > 0 ? (
              <div>
                {todayType === TodayType.ABLE && (
                  <Tooltip
                    placement="top"
                    color="#152D43"
                    zIndex={9999}
                    title={<div className="tips-title">Claiming this reward will active Rakeboost</div>}
                  >
                    <div
                      className="calendar-btn"
                      onClick={() => {
                        handleReceive && handleReceive();
                      }}
                    >
                      {currentAbleAmount.toFixed(2)}
                      <img src={get_now} alt="" />
                    </div>
                  </Tooltip>
                )}

                {/* {todayType === TodayType.LOCK && (
                  <div>
                    <div className="tomorrow-text">
                      <div>
                        Claim <span style={{ color: '#FFE619' }}>&nbsp;${comoutedAmount(item?.recordList[0])} &nbsp; </span> in
                      </div>
                      <div className="tomorrow-time">
                        {lockCountdown}
                        <Tooltip
                          placement="top"
                          color="#152D43"
                          zIndex={9999}
                          title={<div className="tips-title">Claimable amounts unlock at different times during the day</div>}
                        >
                          <img
                            src={tips}
                            onMouseEnter={(e) => (e.target.src = tips_blue)}
                            onMouseLeave={(e) => (e.target.src = tips)}
                            alt=""
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )} */}
                {todayType === TodayType.NONE && <div style={{ textAlign: 'center' }}>{computedCount(item.recordList)}</div>}
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>{computedCount(item.recordList)}</div>
            )}
          </div>
        ) : (
          <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
            {/* empty */}
            {item.recordList && item.recordList.length === 0 && (
              <div className="calendar-content">
                <div className="calendar-amount-val">${Number(item.amountSum).toFixed(2)}</div>
                <div className="calender-empty"> No Reward</div>
              </div>
            )}
            {item.recordList && item.recordList.length > 0 && (
              <div className="calendar-content">
                <div className="calendar-amount-val">
                  Available: <span style={{ color: '#FFE619' }}>${Number(item.amountSum).toFixed(2)}</span>
                </div>
                <div className="calendar-img">
                  <img src={coin_icon} alt="" />
                </div>
                {/* {isTomorrow(today, item.dateTime) && todayType === TodayType.NONE ? ( */}
                {false ? (
                  <div>
                    <div className="tomorrow-text">
                      <div>
                        Claim <span style={{ color: '#FFE619' }}>&nbsp;${comoutedAmount(item?.recordList[0])} &nbsp; </span> in
                      </div>
                      <div className="tomorrow-time">
                        {countdown}
                        <Tooltip
                          placement="top"
                          color="#152D43"
                          zIndex={9999}
                          title={<div className="tips-title">Claimable amounts unlock at different times during the day</div>}
                        >
                          <img src={tips} alt="" />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ height: '18px', lineHeight: '18px' }}>
                    <img src={Lock_icon} alt="" />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <Dialog
        open={dialogRewordList}
        TransitionComponent={Transition}
        // keepMounted={false}
        // disablePortal={true}
        onClose={closeDialogRewordList}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiPaper-root': {
            width: '360px',
            maxWidth: '360px',
            minHeight: '154px',
            background: '#151E2F',
            padding: 0,
            margin: 0,
            borderRadius: '8px'
          }
        }}
      >
        <DialogContent className="dia-rewards">
          <div className="dia-rewards-top">
            {/* <div>ALL REWARDS FOR {today === item.dateTime ? 'Today' : formatMonth(item.dateTime)}</div> */}
            <div>ALL REWARDS FOR </div>
            <IconButton
              onClick={closeDialogRewordList}
              sx={{ color: '#576788', width: '12px', position: 'absolute', right: '30px', top: '0px' }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dia-rewards-content">
            {rewordList.map((item, index) => (
              <div key={index} className="dia-rewards-item">
                {today === item.dateTime ? (
                  <img src={item.state === 0 ? pending : green_icon} alt="" style={{ cursor: 'pointer' }} />
                ) : (
                  <img src={item.state === 0 ? red_icon : green_icon} alt="" style={{ cursor: 'pointer' }} />
                )}
                {/* <img src={item.state === 0 ? red_icon : green_icon} alt="" /> */}
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <span style={{ marginRight: 'auto' }}>${comoutedAmount(item)}</span>
                  {/* unlocked at <span>{computedTime(item)}</span> */}
                  {item.state === 1 ? <span className="claimed">Claimed</span> : <span className="unclaimed">Unclaimed</span>}
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CalendarItem;
