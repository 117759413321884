// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_TOKEN = '@customization/SET_TOKEN';
export const SET_USER_INFOR = '@customization/SET_USER_INFOR';
export const SET_ADDRESS = '@customization/SET_ADDRESS';
export const SET_NETWORK_MODEL = '@customization/SET_NETWORK_MODEL';
export const SET_LOGIN_MODEL = '@customization/SET_LOGIN_MODEL';
export const ROUND_INFOR = '@customization/ROUND_INFOR';
export const SET_BNB_PRICE = '@customization/SET_BNB_PRICE';
export const SET_LOGIN_RESIGN_OPEN = '@customization/SET_LOGIN_RESIGN_OPEN';
export const LOGIN_OR_RESI = '@customization/LOGIN_OR_RESI';
export const SET_SUBMIT_NICKNAME_OPEN = '@customization/SET_SUBMIT_NICKNAME_OPEN';
export const SET_RESETPASSWORD_OPEN = '@customization/SET_RESETPASSWORD_OPEN';
export const SET_USDT_ACCOUNT = '@customization/SET_USDT_ACCOUNT';
export const PROFILE_ID = '@customization/PROFILE_ID';
export const TOTAL_BET_AMOUNT = '@customization/TOTAL_BET_AMOUNT';
export const IS_CASHIER = '@customization/IS_CASHIER';
export const INCRE_PLINKO_VALL_NUMBER = '@customization/INCRE_PLINKO_VALL_NUMBER'
export const DECRE_PLINKO_VALL_NUMBER = '@customization/DECRE_PLINKO_VALL_NUMBER'
export const PLINKO_RUNING = '@customization/PLINKO_RUNING'
export const PLINKO_HASH = '@customization/PLINKO_HASH'
export const IS_BUY_CRYPTO = '@customization/IS_BUY_CRYPTO';
export const IS_COUPONS = '@customization/IS_COUPONS';
export const SIDEBAR_MENU = '@customization/SIDEBAR_MENU';
export const MENU_ITEM = '@customization/MENU_ITEM';
export const MOBILE_MENU_OPEN = '@customization/MOBILE_MENU_OPEN';
export const SHOW_CHAT = '@customization/SHOW_CHAT';
export const BET_RECORD_LIST = '@customization/BET_RECORD_LIST';
export const BET_ALL_RECORD_LIST = '@customization/BET_ALL_RECORD_LIST';
export const SHOW_NOTICE = '@customization/SHOW_NOTICE';
export const SHOW_NOTICE_CONTENT = '@customization/SHOW_NOTICE_CONTENT';
export const SET_TRADE_LIST = '@customization/SET_TRADE_LIST';
export const SET_UPDATE_CLAIM = '@customization/SET_UPDATE_CLAIM'
export const SET_GLOBAL_LOADING = '@customization/SET_GLOBAL_LOADING'
export const SET_WEBSCOKET_LOGIN= '@customization/WEBSCOKET_LOGIN'
export const SET_FPS= '@customization/SET_FPS'
export const SET_SPORT_VIEW= '@customization/SET_SPORT_VIEW'
export const SET_PROVIDES_LIST= '@customization/SET_PROVIDES_LIST'
export const BET_RECORD_HISTORY_LIST= '@customization/BET_RECORD_HISTORY_LIST'
export const SET_2FA_VERIFY= '@customization/SET_2FA_VERIFY'
export const SET_IP_VERIFIY= '@customization/SET_IP_VERIFIY'
export const SET_WALLET_TYPE= '@customization/SET_WALLET_TYPE'
export const SET_EMAIL_CODE= '@customization/SET_EMAIL_CODE'
export const SET_WEENRACE_SHOW= '@customization/SET_WEENRACE_SHOW'
