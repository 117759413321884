import React, { useEffect, useState } from 'react';
import { DialogContent, Dialog, Slide, Divider, DialogTitle, useMediaQuery, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

//utils
import { useGenerateNonce } from 'utils/useGenerateNonce';
import { awaitWrap } from 'utils/utilTools';
import { registerByAccount, loginByAccount, loginByWallet } from 'store/actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SUBMIT_NICKNAME_OPEN, SET_RESETPASSWORD_OPEN, SET_EMAIL_CODE } from 'store/actions';
import { setEvents } from 'utils/setEvents';
import getWalletInfor from 'utils/walletConfig';
import { sendRegistrationCode } from 'utils/api/userSet';
import './index.scss';
//wagmi
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import CloseIcon from '@mui/icons-material/Close';

import { loginOn, loginOff, resiginOff, resiginOn, logo, loginVerify_down } from 'static/images';

//antd
import { Input, Form } from 'antd';
import { useSnackbar } from 'notistack';
import SumitNicknameModel from 'ui-component/extended/sumitNicknameModel';
import ResetPassword from 'ui-component/resetPassword';
import ResigerEmail from 'ui-component/resigerEmail';
import Transitions from 'ui-component/extended/Transitions';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});

const customizeRequiredMark = (label, { required }) => (
  <>
    {<span style={{ fontFamily: `"Geogrotesque Wide", sans-serif`, fontSize: '16px', lineHeight: 1.5 }}>{label}</span>}
    {required ? <span style={{ color: 'RGBA(239, 182, 30, 1)', marginLeft: '5px' }}> *</span> : ''}
  </>
);
export default function LoginAndResiger(props) {
  const { open=false, onClose, type } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const customization = useSelector((state) => state.customization);
  const { getGenerateNonce } = useGenerateNonce();
  const { connectAsync, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const walletInfor = getWalletInfor(connectors);
  const [form] = Form.useForm();
  const [tabs, setTabs] = useState('login');
  const [show2FaCode, setShow2FaCode] = useState(false);
  const [email, setEmail] = useState('');
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setTabs(type);
  }, [type]);

  async function signStringData(address) {
    let [signData] = await awaitWrap(getGenerateNonce(address));
    if (!signData) {
      disconnect();
      return;
    }
    loginByWalletHandle(address, signData);
  }

  async function loginByWalletHandle(address, signature) {
    try {
      await dispatch(loginByWallet({ address, signature }));
      enqueueSnackbar('Login successfully', { variant: 'success' });
      dispatch({ type: SET_SUBMIT_NICKNAME_OPEN, sumitNicknameModel: false });
      onClose();
      gtag('event', 'get_login', {
        loginType: 'wallet',
        isInvite: window.localStorage.getItem('invit_code') ? `ad-wallet` : `no-wallet`
      });
      if (window.localStorage.getItem('invit_code') === 'bitcoinist') {
        gtag('event', 'bitcoinist_get_login', {
          type: 'wallet'
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.msg || 'error', { variant: 'error' });
    }
  }

  async function connectWallet() {
    try {
      setEvents(1);
      const connector = walletInfor.find((item) => item.name === 'METAMASK').connctor;
      if (address) {
        signStringData(address);
        return;
      }
      const res = await connectAsync({
        connector: connector
      });
      signStringData(res.account);
    } catch (error) {
      console.log(error);
    }
  }
  async function submit() {
    try {
      if (tabs === 'login') {
        login();
      } else {
        register();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handlekeyDown = (e) => {
    if (e.key === 'Enter') submit();
  };

  async function submitBywallet() {
    if (tabs === 'login') {
      connectWallet();
    } else {
      dispatch({ type: SET_SUBMIT_NICKNAME_OPEN, sumitNicknameModel: true });
    }
  }
  async function login() {
    try {
      const res = await form.validateFields();
      await dispatch(loginByAccount({ ...res }));
      enqueueSnackbar('Login successfully', { variant: 'success' });
      gtag('event', 'get_login', {
        loginType: 'account',
        isInvite: window.localStorage.getItem('invit_code') ? `ad-account` : `no-account`
      });
      if (window.localStorage.getItem('invit_code') === 'bitcoinist') {
        gtag('event', 'bitcoinist_get_login', {
          type: 'account'
        });
      }

      onClose();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.msg || 'error', { variant: 'error' });
    }
  }
  async function register() {
    const res = await form.validateFields();
    setEmail(res.account);
    await resetCode();
    // dispatch({ type: SET_EMAIL_CODE, showEmailCode: true });
  }
  const resigerComfirm = async (code) => {
    try {
      const res = await form.validateFields();
      await dispatch(registerByAccount({ ...res, accountType: 1 ,verificationCode:code}));
      enqueueSnackbar('Register successfully', { variant: 'success' });
      // login();
      window.localStorage.removeItem('firstDepositComplated');
      gtag('event', 'get_register', {
        registerType: 'account',
        isInvite: window.localStorage.getItem('invit_code') ? `ad-account` : `no-account`
      });
      if (window.localStorage.getItem('invit_code') === 'bitcoinist') {
        gtag('event', 'bitcoinist_get_register', {
          type: 'account'
        });
      }
      if (window.localStorage.getItem('invit_code') === 'fbpro' || window.localStorage.getItem('invit_code') === 'fbpro2') {
        fbq('track', 'CompleteRegistration');
      }
      dispatch({ type: SET_EMAIL_CODE, showEmailCode: false })
      onClose();
    } catch (error) {
      enqueueSnackbar(error.msg || 'error', { variant: 'error' });
      console.log(error);
    }
  };
  const resetCode = async () => {
    try {
      const res = await form.validateFields();
      const data = await sendRegistrationCode({ account: res.account, type: 0 });
      if (data.code == 200) {
        enqueueSnackbar(`Send code to ${res.account}`, { variant: 'success' });
      }
      setEmail(res.account);
      dispatch({ type: SET_EMAIL_CODE, showEmailCode: true });
    } catch (error) {
      if(error.code === 5015){
        dispatch({ type: SET_EMAIL_CODE, showEmailCode: true });
      }
      enqueueSnackbar(error.msg || 'error', { variant: 'error' });
    }
  };
  return (
    <div className="loginAndResigin">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        disablePortal={true}
        onClose={() => onClose && onClose()}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '840px',
            width: matchUpMd ? '95vw' : '840px',
            height: matchUpMd ? '100%' : '560px',
            maxHeight: matchUpMd ? '100%' : '560px',
            padding: 0,
            margin: 0,
            borderRadius: '8px'
          }
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => onClose && onClose()}
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            color: '#576788',
            zIndex: 2,
            transition: 'all 0.2s ease-in',
            '&:hover': {
              color: '#5096FF'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="content" style={{ flexDirection: matchUpMd ? 'column' : 'row' }}>
          <div className="right" style={{ width: matchUpMd ? '100%' : '50%' }}>
            {matchUpMd && (
              <div className="mobile-top">
                <div className="mobile-dir">
                  <img src={logo} alt="" />
                  <div>
                    By accessing the site, I attest that I am at least 18 years old and have read the{' '}
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/info/game_terms')}>
                      Terms and Conditions
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="tabs">
              <div className="tabs-content">
                <div className={tabs === 'login' ? 'tabsItem tabs-active' : 'tabsItem'} onClick={() => setTabs('login')}>
                  <img src={tabs == 'login' ? loginOn : loginOff} alt="" width="15px" />
                  <div className="lable">LOGIN</div>
                </div>
                {matchUpMd && <span style={{ color: '#576788' }}>|</span>}
                <div className={tabs === 'resigin' ? 'tabsItem tabs-active' : 'tabsItem'} onClick={() => setTabs('resigin')}>
                  <img src={tabs == 'resigin' ? resiginOn : resiginOff} alt="" width="15px" />
                  <div className="lable">REGISTER</div>
                </div>
              </div>
            </div>
            <div className="right_content">
              <div>
                <Form form={form} layout="vertical" requiredMark={customizeRequiredMark}>
                  {tabs === 'resigin' && (
                    <Form.Item
                      label="USERNAME"
                      required
                      rules={[{ required: true, message: 'Username is require' }]}
                      name="userName"
                      style={{ marginBottom: '22px' }}
                    >
                      <Input size="large" placeholder="Username" autoComplete="off" />
                    </Form.Item>
                  )}
                  <Form.Item
                    label="EMAIL"
                    rules={[
                      { required: true, message: 'Email is require' },
                      { type: 'email', warningOnly: true, message: 'Email is not a valid email' }
                    ]}
                    name="account"
                    style={{ marginBottom: '22px' }}
                  >
                    <Input size="large" placeholder="youremail@domain.com" autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label="PASSWORD"
                    required
                    name="password"
                    style={{ marginBottom: '5px' }}
                    rules={[{ required: true, message: 'Password is require' }]}
                    onKeyDown={handlekeyDown}
                  >
                    <Input.Password size="large" placeholder="********" autoComplete="off" />
                  </Form.Item>
                  <div
                    style={{ cursor: 'pointer', color: '#576788', textAlign: 'right' }}
                    onClick={() => dispatch({ type: SET_RESETPASSWORD_OPEN, resetPasswordModel: true })}
                  >
                    Forgot password?
                  </div>
                  {tabs === 'login' && (
                    <Form.Item
                      label={
                        <div
                          onClick={() => setShow2FaCode(!show2FaCode)}
                          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        >
                          2FA CODE <img src={loginVerify_down} alt=""  width={16} />
                        </div>
                      }
                      name="code"
                      style={{ marginBottom: matchUpMd ? '32px' : '50px' }}
                      onKeyDown={handlekeyDown}
                      extra={show2FaCode && <div style={{ color: '#576788' }}>Required if you enabled two-factor authentication.</div>}
                    >
                      {show2FaCode && <Input size="large" placeholder="Code" autoComplete="off" />}
                    </Form.Item>
                  )}
                </Form>
              </div>

              {/* <div>This site is protected by reCAPTCHA and the Google Privacy policy and Terms of Service apply.</div> */}
              <LoadingButton
                onClick={submit}
                disableRipple
                sx={{
                  height: '40px',
                  minWidth: '100%',
                  background: '#FFE619',
                  fontSize: '20px',
                  color: '#302B05',
                  borderRadius: '5px',
                  fontFamily: 'Geogrotesque Bl',
                  '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
                }}
              >
                {tabs === 'resigin' ? 'START PLAYING' : 'LOGIN'}
              </LoadingButton>
              <div style={{ textAlign: 'center', margin: '11px 0', color: '#7E95C3' }}>or continue with</div>
              <LoadingButton
                onClick={submitBywallet}
                disableRipple
                sx={{
                  height: '40px',
                  minWidth: '100%',
                  background: '#FFE619',
                  fontSize: '20px',
                  color: '#302B05',
                  borderRadius: '5px',
                  fontFamily: 'Geogrotesque Bl',
                  '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
                }}
              >
                WALLET
              </LoadingButton>
            </div>
          </div>
          {!matchUpMd && (
            <div className="left">
              <div className="dir">
                By accessing the site, I attest that I am at least 18 years old and have read the{' '}
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/info/game_terms')}>
                  Terms and Conditions
                </span>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <SumitNicknameModel
        open={customization.sumitNicknameModel}
        onClose={() => dispatch({ type: SET_SUBMIT_NICKNAME_OPEN, sumitNicknameModel: false })}
        comfirm={() => connectWallet()}
      />
      <ResetPassword
        open={customization.resetPasswordModel}
        type={customization.type}
        onClose={() => dispatch({ type: SET_RESETPASSWORD_OPEN, resetPasswordModel: false })}
      />
      <ResigerEmail
        open={customization.showEmailCode}
        email={email}
        onClose={() => dispatch({ type: SET_EMAIL_CODE, showEmailCode: false })}
        comfirm={resigerComfirm}
        resetCode={resetCode}
      />
    </div>
  );
}
