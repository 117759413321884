import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

// material-ui
import { ButtonBase, Box, useMediaQuery } from '@mui/material';

// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';
import { MENU_OPEN } from 'store/actions';
import { logo } from 'static/images';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('600'));
  const matchDown450 = useMediaQuery(theme.breakpoints.down('450'));
  return (
    <ButtonBase disableRipple onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} component={Link} to={config.defaultPath}>
      {/* <Logo /> */}
      <Box
        component="img"
        sx={{
          marginRight: '5px',
          marginBottom: '2px',
          width: matchUpMd ? 'auto' : '120px'
          // display: matchDown450 && 'none'
        }}
        src={logo}
      />
    </ButtonBase>
  );
};

export default LogoSection;
