import { Tabs, Tab, Box } from '@mui/material';

export default function ModelTabs(props) {
  const { tabValue, tabs, changeTab } = props;
  const handleChangeTab = (e, tab) => {
    changeTab && changeTab(tab);
  };
  return (
    <Box sx={{}}>
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        // variant="fullWidth"
        sx={{
          display: 'flex',
          alignItems: 'center',
          pl: '18px',
          // overflow: 'auto',
          // '& .MuiTabs-scroller': {
          //   overflow: 'auto !important',
          //   '::-webkit-scrollbar': {
          //     // display: 'none'
          //   }
          // },
          '& .MuiTabs-flexContainer': {
            background: '#192336',
            boxSizing: 'border-box',
            borderRadius: '13px',
            color: '#576788',
            alginItem: 'center',
            '@media (max-width: 650px)': {
              border: '0',
              // minWidth: '475px',
              // maxWidth: '475px',
              overflowX: 'scroll'
            },
            '@media (max-width: 490px)': {
              border: '0',
              // minWidth: '390px'
            },
            '& .MuiTab-textColorPrimary': {
              height: '25px',
              minHeight: '25px',
              padding: '4px 20px'
            }
          },
          '& .MuiTabs-indicator ': {
            display: 'none'
          }
        }}
      >
        {tabs &&
          tabs.map((tab) => {
            if (typeof tab === 'string') return <Tab key={tab} label={tab} sx={{ color: '#576788', fontWeight: 600 }} />;
            else
              return (
                <Tab
                  key={tab.label}
                  disableRipple
                  className={tabValue === tab.value ? 'tab-item-active' : ''}
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={tabValue === tab.value ? tab.iconOn : tab.icon}
                        alt={tab.label}
                        style={{ width: '18px', height: '18px', marginRight: '6px' }}
                      />
                      {tab.label}
                    </div>
                  }
                  value={tab.value}
                  sx={{
                    color: '#576788',
                    fontSize: '15px',
                    fontWeight: 600,
                    transition: 'all .2s ease-in',
                    '&:hover': {
                      background: '#0d131d',
                      color: '#7690c5',
                      borderRadius: '13px'
                    }
                  }}
                ></Tab>
              );
          })}
      </Tabs>
    </Box>
  );
}
