import { Box } from '@mui/material';
import { Input } from 'antd';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState, useEffect } from 'react';
import { ICON1 } from 'static/images';
import { claimCoupons, accountCoupons, getCouponsProgress } from 'utils/api/index';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { getUserAccount } from 'store/actions/userAction';
import BlackDialog from 'self-component/DialogSelf';
import BottomSelf from 'self-component/BottonSelf';

import './scss/coupons.scss';

export default function Coupons() {
  const [loading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [tips, setTips] = useState(false);
  const [couponsPrsent, setCouponsPrsent] = useState(0);
  const [claimCouponTips, setClaimCouponTips] = useState(false);

  const handleSubmit = async () => {
    if (!couponCode) return;
    try {
      setLoading(true);
      const length = couponCode.length;
      if (length === 6) {
        const {data} = await getCouponsProgress();
        if(data.isActivity){
          const prsent = (((data.couponsWager*1)/(data.couponsTargetWager*1))*100).toFixed(2)
          setClaimCouponTips(true);
          setCouponsPrsent(prsent)
          setLoading(false);
          return
        }else{
          await claimCoupons({ code: couponCode });
        }
      } else {
        await accountCoupons({ redeemCode: couponCode });
      }
      await dispatch(getUserAccount());
      setLoading(false);
      enqueueSnackbar('Coupons redeemed successfully.', { autoHideDuration: 2000, variant: 'success' });
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.msg, { autoHideDuration: 2000 });
      setLoading(false);
    }
  };
  const claimCouponsHandle = async () => {
    if (!couponCode) return;
    try {
      await claimCoupons({ code: couponCode });
      await dispatch(getUserAccount());
      enqueueSnackbar('Coupons redeemed successfully.', { autoHideDuration: 2000, variant: 'success' });
      setClaimCouponTips(false);
    } catch (e) {
      enqueueSnackbar(e.msg, { autoHideDuration: 2000 });
    }
  };
  const goUrl = () => {
    window.open('https://twitter.com/21bitsofficial');
  };

  const handleInput = (val) => {
    setCouponCode(val);
  };

  return (
    <Box className="coupons-box">
      <Box className="box-content">
        <Box className="box-title">REDEEM COUPON CODE</Box>
        <Input
          size="large"
          placeholder="Enter Coupon Code"
          autoComplete="off"
          value={couponCode}
          onChange={(e) => handleInput(e.target.value)}
        />
        <Box className="box-desc">
          We regularly post these on our{' '}
          <span onClick={goUrl} style={{ cursor: 'pointer' }}>
            Twitter
          </span>
        </Box>
        <LoadingButton
          loading={loading}
          disableRipple
          onClick={handleSubmit}
          className="submit-btn"
          sx={{
            fontFamily: `"Geogrotesque Bl", sans-serif`
            // boxShadow:
            //   '0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 2px 0px rgba(240,108,0,1), 0px 0px 3px 0px rgba(240,108,0,1), 0px 0px 5px 0px rgba(240,108,0,1), 0px 0px 10px 0px rgba(240,108,0,1)'
          }}
          // sx={{ '&:hover': { backgroundColor: '#ffe619' } }}
        >
          CLAIM
        </LoadingButton>
        <LoadingButton
          onClick={() => setTips(true)}
          // loading={loading}
          disableRipple
          sx={{
            fontSize: '14px',
            color: '#5583AE',
            borderRadius: '5px',
            background: '#152D43',
            marginTop: '14px',
            // width: '84px',
            minWidth: '100%',
            height: '30px',
            lineHeight: '30px',
            fontFamily: `"Geogrotesque Bl", sans-serif`
            // boxShadow:
            //   '0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 2px 0px rgba(240,108,0,1), 0px 0px 3px 0px rgba(240,108,0,1), 0px 0px 5px 0px rgba(240,108,0,1), 0px 0px 10px 0px rgba(240,108,0,1)'
          }}
        >
          Learn More
        </LoadingButton>
      </Box>
      <Box className="coupons-icon">
        <img src={ICON1} alt="icon" className="icon" />
      </Box>
      <BlackDialog
        open={tips}
        onClose={() => setTips(false)}
        width={540}
        title={<div style={{ display: 'flex', gap: '6px' }}>CouponsTerms </div>}
      >
        <div
          className="vault-content"
          style={{
            '&>p': {
              marginTop: '10px'
            }
          }}
        >
          <p style={{ marginTop: '10px' }}>1.The amount obtained through Coupons can be used for betting on any game at 21bits. </p>
          <p style={{ marginTop: '10px' }}>
            2.Once you redeem a Coupon, you cannot redeem another one until the first Coupon is completed. If you want to redeem a second
            one, you need to forfeit the first Coupon.
          </p>
          <p style={{ marginTop: '10px' }}>3.The bonus from Coupons can only be unlocked by meeting the required wagering multiplier.</p>
          <p style={{ marginTop: '10px' }}>
            4.You don't need to worry; once the Coupon meets the wagering requirement, your reward will be directly added to your Primary
            Balance.
          </p>
          <p style={{ marginTop: '10px' }}>
            5.During the promotion, the maximum bet amount is 1% of the Coupon value. If your Coupon amount is too small, the minimum
            maximum bet amount is $0.2.
          </p>
          <p style={{ marginTop: '10px' }}>6.This Coupon bonus is only applicable to the casino section.</p>
          <p style={{ marginTop: '10px' }}>
            7.We may, at our sole discretion, change the terms and conditions, the promotion itself, or limit the eligibility of players to
            participate in this promotion for any reason without prior notice.
          </p>
        </div>
      </BlackDialog>
      <BlackDialog
        open={claimCouponTips}
        onClose={() => setClaimCouponTips(false)}
        width={540}
        title={<div style={{ display: 'flex', gap: '6px' }}>CANCEL PROMOTION</div>}
      >
        <div
          className="vault-content"
          style={{
            '&>p': {
              marginTop: '10px'
            }
          }}
        >
          <p style={{ marginTop: '10px'}}>
            {`Your current coupons have reached  ${couponsPrsent}%, do you want to give up the redeemed coupons and replace them with new coupons?`}
          </p>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              fontFamily: 'Geogrotesque Bl',
              paddingTop: '30px',
              marginTop: '10px',
              justifyContent: 'space-between'
            }}
          >
            <BottomSelf
              onClick={claimCouponsHandle}
              sx={{
                minWidth: '45%',
                height: '30px',
                lineHeight: '30px'
              }}
            >
              CONFIRM
            </BottomSelf>
            <BottomSelf
             onClick={() => setClaimCouponTips(false)}
              type="commom"
              sx={{
                minWidth: '45%',
                height: '30px',
                lineHeight: '30px'
              }}
            >
              CANCEL
            </BottomSelf>
          </Box>
        </div>
      </BlackDialog>
    </Box>
  );
}
