import { fetchPost, fetchGet } from '../useRequest';

// export function getCasinoGames(data) {
//   return fetchPost('/threeGame/IntegrationService/v3/http/CasinoGameAPI/getCasinoGames', data, {
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   });
// }
export function getCasinoGames(data) {
  return fetchPost('/game-api/gameList', data);
}
export function getGameProviderList(data) {
  return fetchPost('/game-api/gameProviderList', data);
}

export function getOpenGamesUrl(data) {
  return fetchPost('/game-api/gameUrl', data);
}
export function getGenPpToken(data) {
  return fetchPost('/game-api/genPpToken', data);
}

export function getUserCollection(data) {
  return fetchPost('/game-api/getFavorite', data,{ headers: { isLoading: false } });
}

export function addFavGame(data) {
  return fetchPost('/game-api/addFavorite', data,{ headers: { isLoading: false } });
}
export function removeFavGame(data) {
  return fetchPost('/game-api/removeFavorite', data,{ headers: { isLoading: false } });
}
// game_types":[1,2,3]
export function homeGameList(data) {
  return fetchPost('/game-api/homeGameList', data);
}
export function ppActivity(data) {
  return fetchPost('/game-api/ppActivity',data,{ headers: { isLoading: false } });
}



// http://13.229.29.111:8000/game-api/addFavorite