import { useState, useRef, useEffect } from 'react';

import { Tabs, Tab } from 'self-component/TabsSelf';
import SelectSelf from 'self-component/SelectSelf';
import BlackDialog from 'self-component/DialogSelf';
import { getStatistics, getGameStatisticsList } from 'utils/api';

import {
  trophies,
  trophies_green,
  statistic,
  download,
  download_green,
  statistic_green,
  statistic_yellow,
  trophies_yellow,
  statistic_0,
  DOLLAR
} from 'static/images';
import { enqueueSnackbar } from 'notistack';
import { toThousands } from 'utils/commonUtils';
import moment from 'moment';

const Statistics = (props) => {
  const { open, onClose } = props;
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const [tabsIndex, setTabsIndex] = useState(0);
  const [gameType, setGameType] = useState(0);
  const [currencyType, setCurrencyType] = useState(0);
  const [dataList, setDataList] = useState(new Array(5).fill({ label: '', value: '' }));
  const [winType, setWinType] = useState(0);
  const [createTime, setCreateTime] = useState('');

  const statisticTypeOptions = [
    { label: 'All', value: 0 },
    { label: 'CASINO', value: 1 },
    { label: 'SPORT', value: 2 }
  ];
  const currencyOptions = [
    { label: 'All', value: 0 },
    { label: 'USDT', value: 1 }
  ];
  const trophiesOptions = [
    { label: 'Luckiest', value: 0 },
    { label: 'Biggest', value: 1 }
  ];

  const getStatisticsList = async () => {
    const res = await getStatistics({ type: gameType });
    console.log(res);
    if (res.code !== 200) return enqueueSnackbar(res.msg, { variant: 'error' });
    const data = res.data;
    data.winningRate = data.winningRate * 100;
    const statisticContentList = [
      { label: 'Total Bets', value: toThousands(Number(data.totalBets)) },
      { label: 'Number of Wins', value: toThousands(Number(data.numberOfWins)) },
      { label: 'Number of Losses', value: toThousands(Number(data.numberOfLosses)) },
      { label: 'Winning Rate', value: toThousands(Number(data.winningRate)), addonAfter: '%' },
      { label: 'Wagered', value: toThousands(Number(data.wagered)), addonBefore: '$', addonAfter: <img src={DOLLAR} alt="" /> }
    ];
    setDataList(statisticContentList);
  };

  const handleExport = async () => {
    const res = await getGameStatisticsList({ type: gameType });
    console.log(res);
    if (res.code !== 200) return enqueueSnackbar(res.msg, { variant: 'error' });
    else return enqueueSnackbar(res.msg, { variant: 'success' });

    // const data = res.data;
    // setDataList(statisticContentList);
  };

  useEffect(() => {
    if (open) getStatisticsList();
  }, [gameType, open]);

  useEffect(() => {
    const registerTime = userInfo?.registrationTime;
    registerTime && setCreateTime(moment(registerTime).format('MMM DD,YYYY'));
  }, [userInfo]);

  return (
    <BlackDialog
      open={open}
      onClose={() => onClose && onClose()}
      width={540}
      title={
        <div style={{ display: 'flex', gap: '6px' }}>
          <img src={statistic_green} style={{ width: '20px' }} />
          STATISTICS
        </div>
      }
    >
      <div className="statistic-content">
        <div className=" statistic-content__title">{userInfo.nickName}</div>
        <div className=" statistic-content__desc">Joined on {createTime}</div>

        <div style={{ marginTop: '22px' }}>
          <Tabs headerStyle={{ margin: 0 }} isCenter={false} onChange={(index) => setTabsIndex(index)} activeIndex={tabsIndex}>
            <Tab tab="Statistics" activeIcon={statistic_yellow} closeIcon={statistic}></Tab>
            <Tab tab="Trophies" activeIcon={trophies_yellow} closeIcon={trophies}></Tab>
          </Tabs>
        </div>
        {tabsIndex === 0 && (
          <div style={{ marginTop: '35px' }}>
            <div className="statistic-select-row">
              <div>
                <SelectSelf
                  title={'Type'}
                  defaultValue={gameType}
                  list={statisticTypeOptions}
                  open={false}
                  onChange={(val) => setGameType(val)}
                />
              </div>
              <div>
                <SelectSelf
                  title={'Currency'}
                  defaultValue={currencyType}
                  list={currencyOptions}
                  open={false}
                  onChange={(val) => setCurrencyType(val)}
                />
              </div>
            </div>

            <div className="statistic-content-box">
              {dataList.map((item, index) => (
                <div key={index} className="statistic-content-item">
                  <div className="statistic-content-item__label">{item.label}</div>
                  <div className="statistic-content-item__value">
                    {item?.addonBefore}
                    {item.value}
                    {item?.addonAfter}
                  </div>
                </div>
              ))}
            </div>

            <div className="statistic-export" onClick={handleExport}>
              <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.714447" y="5.99268" width="2" height="7" rx="1" transform="rotate(-45 0.714447 5.99268)" fill="#797979" />
                <rect x="9.1998" y="4.57837" width="2" height="7" rx="1" transform="rotate(45 9.1998 4.57837)" fill="#797979" />
                <rect x="4.71448" y="0.942383" width="2" height="8" rx="1" fill="#797979" />
                <rect x="10.614" y="10.9424" width="2" height="10" rx="1" transform="rotate(90 10.614 10.9424)" fill="#797979" />
              </svg>
              Request Statistics
            </div>
          </div>
        )}
        {tabsIndex === 1 && (
          <div className="Trophies-content-box">
            <div className="Trophies-top">
              <div className="Trophies-top__title">
                <img src={trophies_green} />
                Luckiest Wins
              </div>
              <div className="Trophies-top__select">
                <SelectSelf
                  title={''}
                  defaultValue={winType}
                  isCenter={true}
                  list={trophiesOptions}
                  open={false}
                  onChange={(val) => setWinType(val)}
                />
              </div>
            </div>

            <div className="Trophies-content">
              <div className="Trophies-content__noData">
                <img src={statistic_0} alt="" />
                <span>This user has no visible statistics</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </BlackDialog>
  );
};

export default Statistics;
