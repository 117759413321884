import React, { useState, useRef, useEffect } from 'react';
import { Slide, DialogContent, Dialog, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
//utils
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Table } from 'antd';

//wagmi
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

import Transitions from 'ui-component/extended/Transitions';
import StripeSlider from 'ui-component/stripeSlider';
import { isEmptyObject } from 'utils/utilTools';
import { toThousands } from 'utils/commonUtils';
import { getUserAccount } from 'store/actions/userAction';

import { claimCouponsReward, getUserCouponsHistoryList } from 'utils/api';
import './index.scss';

import {
  box_unable,
  box1_unable,
  box1_able,
  box2_unable,
  box2_able,
  box3_unable,
  box3_able,
  box4_unable,
  box4_able,
  green_icon,
  Lock_icon
} from 'static/images';

import classNames from 'classnames';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});
const UNCLAIM = 1;
const CLAIM = 2;

export default function BounsModel(props) {
  const { open, onClose, bonusData, onReload } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPercent, setCurrentPercent] = useState(0);
  const [allAmount, setAllAmount] = useState(0);
  const [lockAmount, setLockAmount] = useState(0);
  const [bonusTaskList, setBonusTaskList] = useState([]);
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.down('md'));

  const [tableLoading, setTableLoding] = useState(false);
  const [historyList, setHistoryList] = useState([]);

  const [openHistory, setOpenHistory] = useState(false);

  const position = [15, 40, 70, 100];
  const giftList = () => {
    if (isEmptyObject(bonusData) || !bonusData) return [];
    const taskList = bonusData.couponsTaskList || [];
    const list = [
      {
        unclaimed: box1_unable,
        claimed: box1_able
      },
      {
        unclaimed: box2_unable,
        claimed: box2_able
      },
      {
        unclaimed: box3_unable,
        claimed: box3_able
      },
      {
        unclaimed: box4_unable,
        claimed: box4_able
      }
    ];

    return taskList.map((item, index) => {
      return {
        ...list[index],
        position: position[index] + '%',
        id: item.id,
        state: item.state,
        isComplete: item.isComplete,
        multiple: item.multiple
      };
    });
  };

  const handleClaimCoupons = async (item) => {
    if (!item.isComplete || item.state === CLAIM) return;
    try {
      const res = await claimCouponsReward({ code: bonusData.coupons, id: item.id });
      if (res.code === 200) {
        enqueueSnackbar('success', { variant: 'success' });
        onReload && onReload();
        await dispatch(getUserAccount());
      }
    } catch (e) {
      enqueueSnackbar(e.msg, { variant: 'error' });
    }
  };

  const columns = [
    {
      title: 'Create Time',
      dataIndex: 'createTime',
      align: 'center'
    },
    {
      title: 'Code',
      dataIndex: 'code',
      align: 'center'
    },
    {
      title: 'Amount',
      align: 'center',
      dataIndex: 'exchangeAmount'
    },
    {
      title: 'State',
      dataIndex: 'state',
      align: 'center',
      render: (text, record) => {
        let state = text === 2 ? 'Redeemed' : text === 3 ? 'Claimed' : 'Expired';
        return state;
      }
    }
  ];

  const getHistoryList = async () => {
    setTableLoding(true);
    try {
      const res = await getUserCouponsHistoryList();
      if (res.code === 200) {
        setHistoryList(res.data || []);
        setTableLoding(false);
      }
    } catch (e) {
      setTableLoding(false);
      enqueueSnackbar(e.msg || 'error', { variant: 'error' });
    }
  };

  const handleShowHistory = () => {
    setOpenHistory(true);
    getHistoryList();
  };

  useEffect(() => {
    if (isEmptyObject(bonusData) || !bonusData) return;
    const taskList = bonusData.couponsTaskList || [];
    const index = taskList.findLastIndex((item) => item.isComplete === true);
    setCurrentPercent(position[index]);
    setBonusTaskList(taskList);

    const lock_amount = taskList.reduce((pre, cur) => {
      if (cur.state === UNCLAIM) return pre + Number(cur.amount);
      else return pre;
    }, 0);
    setLockAmount(lock_amount.toFixed(2));

    const amount = taskList.reduce((pre, cur) => {
      return pre + Number(cur.amount);
    }, 0);
    setAllAmount(amount.toFixed(2));
  }, [bonusData]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        disablePortal={true}
        onClose={() => onClose && onClose()}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '540px',
            width: matchUpMd ? '95vw' : '540px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 8px 8px rgba(0,0,0,0.3)'
          }
        }}
      >
        <div>
          <div className="dia-top">
            <div className="dia-top-arrow" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                aria-label="close"
                onClick={() => onClose && onClose()}
                sx={{
                  color: '#576788',
                  transition: 'all 0.2s ease-in',
                  '&:hover': {
                    color: '#5096FF',
                    background: 'none'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="dia-top-text">BONUS</div>
          </div>

          <DialogContent sx={{ background: '#151E2F', padding: '18px 30px 32px 40px' }}>
            <div>
              <div className="gift-progress-box">
                <div className="progress-box">
                  <StripeSlider percent={currentPercent} background={'#000'} />
                  {giftList().length > 0 &&
                    giftList().map((item, index) => (
                      <div key={item.id} className="progress-box-item" style={{ left: item.position }}>
                        <img
                          src={!item.isComplete ? box_unable : item.state === UNCLAIM ? item.unclaimed : item.claimed}
                          style={{ width: '76px', height: '76px' }}
                          alt=""
                          className={classNames({ cursor: item.isComplete && item.state === UNCLAIM })}
                          onClick={() => handleClaimCoupons(item)}
                        />
                        <div className="progress-icon">
                          {!item.isComplete ? (
                            <div style={{ width: '15px', height: '15px', background: '#000', borderRadius: '50%' }}></div>
                          ) : (
                            <img src={green_icon} style={{ width: '15px' }} alt="" />
                          )}
                        </div>

                        <div className={classNames('multiple', { 'mul-active': item.isComplete })}>{item.multiple}x</div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="gift-info">
                <div className="gift-info-left">
                  <img src={Lock_icon} alt="" />
                  <div>Locked</div>
                </div>
                <div>$ {toThousands(lockAmount)}</div>
              </div>
            </div>

            <div style={{ lineHeight: '1.35' }}>
              <div style={{ fontSize: '18px' }}>Unlocking Your Bonus</div>
              <div style={{ fontSize: '14px', color: '#8299C8', marginTop: '14px' }}>
                <div>
                  To unlock the full bonus, you must wager <span style={{ color: '#FFE619' }}>$ {toThousands(bonusData.totalFlow)}</span> to
                  release a bonus amount of <span style={{ color: '#FFE619' }}>$ {allAmount}</span>.
                </div>
                <div>
                  You have already completed <span style={{ color: '#fff' }}>$ {toThousands(bonusData.completedFlow)}</span> of the wagering
                  requirement.{' '}
                </div>
                <div>You can claim the full bonus in batches over four stages:</div>
                <div style={{ marginTop: '14px' }}>
                  Stage 1: Complete <span style={{ color: '#fff' }}>$ {toThousands(bonusTaskList[0]?.flow)}</span> in wagers to unlock{' '}
                  <span style={{ color: '#fff' }}>$ {toThousands(bonusTaskList[0]?.amount)}</span>.
                </div>
                <div>
                  Stage 2: Complete <span style={{ color: '#fff' }}>$ {toThousands(bonusTaskList[1]?.flow)}</span> in wagers to unlock{' '}
                  <span style={{ color: '#fff' }}>$ {toThousands(bonusTaskList[1]?.amount)}</span>.
                </div>
                <div>
                  Stage 3: Complete <span style={{ color: '#fff' }}>$ {toThousands(bonusTaskList[2]?.flow)}</span> in wagers to unlock{' '}
                  <span style={{ color: '#fff' }}>$ {toThousands(bonusTaskList[2]?.amount)}</span>.
                </div>
                <div>
                  Stage 4: Complete <span style={{ color: '#fff' }}>$ {toThousands(bonusTaskList[3]?.flow)}</span> in wagers to unlock
                  <span style={{ color: '#fff' }}>$ {toThousands(bonusTaskList[3]?.amount)}</span>.
                </div>
              </div>
            </div>

            <div className="history-btn" onClick={() => handleShowHistory()}>
              {' '}
              Bonus History
            </div>
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        open={openHistory}
        TransitionComponent={Transition}
        keepMounted={false}
        disablePortal={true}
        onClose={() => setOpenHistory(false)}
        aria-describedby="alert-dialog-history"
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '450px',
            width: matchUpMd ? '80vw' : '450px',
            borderRadius: '8px',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 8px 8px rgba(0,0,0,0.3)'
          }
        }}
      >
        <div>
          <div className="dia-top">
            <div className="dia-top-arrow" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                aria-label="close"
                onClick={() => setOpenHistory(false)}
                sx={{
                  color: '#576788',
                  transition: 'all 0.2s ease-in',
                  '&:hover': {
                    color: '#5096FF',
                    background: 'none'
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="dia-top-text">BONUS HISTORY</div>
          </div>

          <DialogContent sx={{ background: '#151E2F', padding: '18px 18px 32px 20px' }}>
            <div className="bonus-history-table">
              <Table
                style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}
                loading={tableLoading}
                columns={columns}
                dataSource={historyList}
                pagination={false}
                rowKey={(record) => record.code}
              />
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
