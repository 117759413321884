import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './index.scss';
const TabsContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  height: 100%;
  ${(props) =>
    props.$isCenter &&
    `
    align-items: center;
  `}
`;

const TabHeader = styled.div`
  display: flex;
  background: #0f1623;
  border-radius: 14px;
  border: 1px solid #212634;
  margin: 16px 0;
  padding: 2px;
  box-sizing: border-box;
  height: 28px;
  gap: 2px;

  align-items: center;
  overflow-x: scroll;
  ${(props) => props.$headerStyle}
`;

const TabTitle = styled.div`
  box-sizing: border-box;
  height: 24px;
  padding: 6px 20px;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  border-radius: 14px 14px 14px 14px;
  align-items: center;
  font-family: 'Geogrotesque Bl', sans-serif;
  color: #797979;
  border: 1px solid transparent;
  transition: all 200ms ease-in;
  min-width: fit-content;
  ${(props) =>
    props.params.activeIcon &&
    `
    background: #1f283c;
    box-shadow: inset 0px 0px 3px 0px rgba(255,255,255,0.4);
    border-radius: 14px 14px 14px 14px;
    border: 1px solid #0a0d16;
    color: #FFE619;
  `}
  &::before {
    content: '';
    background: url(${(props) => (props.$active ? props.params.activeIcon : props.params.closeIcon)}) no-repeat;
    margin-right: 5px;
    width:${(props) => (props.params.closeIcon ? `15px` : 0)};
    height: 15px;
    display: inline-block;
  }
  &:hover {
    background: #1f283c;
    border-radius: 14px 14px 14px 14px;
    border: 1px solid #0a0d16;
    color: #ffe619;
    box-shadow: inset 0px 0px 3px 0px rgba(255, 255, 255, 0.4);
  }
  &:hover::before {
    content: '';
    background: url(${(props) => props.params.activeIcon}) no-repeat;
    margin-right: 5px;
    width: ${(props) => (props.params.closeIcon ? `15px` : 0)};
    height: 15px;
    display: inline-block;
  }
  ${(props) =>
    props.$active &&
    `
    background: #1f283c;
    box-shadow: inset 0px 0px 3px 0px rgba(255,255,255,0.4);
    border-radius: 14px 14px 14px 14px;
    border: 1px solid #0a0d16;
    color: #FFE619;
  `}
`;

const TabContent = styled.div`
  width: 100%;
  height: 100%;
`;

const Tabs = ({ children, isCenter, onChange, activeIndex = 0, headerStyle, containerStyle,titleStyle }) => {
  const scolRef = useRef();
  const tabsContainer = useRef();
  const [flag, setFlag] = useState(false);
  const handleTabClick = (index, child) => {
    onChange && onChange(index, child);
  };
  function scrollLeft() {
    var container = scolRef.current;
    container.scrollBy({ left: 100, behavior: 'smooth' });
  }
  useEffect(() => {
    var tabsContainerEle = tabsContainer.current;
    var tabHeaderEle = scolRef.current;
    var tabsContainerEleWidth = tabsContainerEle.offsetWidth;
    var tabHeaderEleWidth = tabHeaderEle.scrollWidth;
    setFlag(tabHeaderEleWidth > tabsContainerEleWidth);
    console.log(tabsContainerEleWidth, tabHeaderEleWidth, 'width');
  }, []);
  return (
    <TabsContainer $isCenter={isCenter} className="tabsSelf" ref={tabsContainer} style={{ ...containerStyle }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <img src={left_arrow} alt="" srcset="" /> */}
        <div className="tabsHead">
          <TabHeader $headerStyle={headerStyle} ref={scolRef}>
            {React.Children.map(children, (child, index) => (
              <TabTitle $active={index == activeIndex} params={child.props} onClick={() => handleTabClick(index, child)} style={{...titleStyle}}>
                {child.props.tab}
              </TabTitle>
            ))}
          </TabHeader>
          {flag && (
            <div className="scollFlag" onClick={scrollLeft}>
              <ExpandMoreIcon
                sx={{
                  width: '30px',
                  height: '100%',
                  // background: '#00000044',
                  color: '#797979',
                  borderRadius: '4px',
                  transform: `rotate(${'270deg'})`
                }}
              />
            </div>
          )}
        </div>
        {/* <img src={right_arrow} alt="" srcset="" /> */}
      </div>

      <TabContent>
        {React.Children.toArray(children)[activeIndex]}
      </TabContent>
    </TabsContainer>
  );
};

const Tab = ({ tab, children }) => {
  return <>{children}</>;
};

export { Tabs, Tab };

{
  /* <Tabs isCenter={false} onChange={(index) => setIndex(index)} activeIndex={index}>
<Tab tab="Profile" activeIcon={Profile_icon_green} closeIcon={Profile_icon}>
  <UserInfor matchUpMd={matchUpMd} />
</Tab>
<Tab tab="Vip" activeIcon={VIP_icon_green} closeIcon={VIP_icon}>
  <Vip />
</Tab>
<Tab tab="Referrals" activeIcon={Referrals_icon_green} closeIcon={Referrals_icon}>
  <Refferrals matchUpMd={matchUpMd} />
</Tab>
<Tab tab="Transactions" activeIcon={Transactions_icon_green} closeIcon={Transactions_icon}>
  <Transactions />
</Tab>
<Tab tab="Settings" activeIcon={Settings_icon_green} closeIcon={Settings_icon}>
  <Setting matchUpMd={matchUpMd} />
</Tab>
</Tabs> */
}
