import { useNavigate } from 'react-router-dom';

import {
  casino_yellow,
  casino,
  trade,
  trade_yellow,
  sport,
  sport_yellow,
  airdrop,
  airdrop_yellow,
  profile,
  profile_yellow,
  raceOn,
  raceOff,
  promotions_yellow,
  promotions
} from 'static/images';

import './index.scss';
import react, { useState, useEffect, useRef } from 'react';

import { Box } from '@mui/material';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { MENU_ITEM, SIDEBAR_MENU, MOBILE_MENU_OPEN } from 'store/actions';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const JumpSection = () => {
  const theme = useTheme();
  const scolRef = useRef();

  const [itemLabel, setItemLabel] = useState('CASINO');
  const matchDownMd = useMediaQuery(theme.breakpoints.down('950'));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentSidebarMenu = useSelector((state) => state.customization.sidebarMenu);
  const isMenuOpen = useSelector((state) => state.customization.mobileMenuOpen);
  const token = window.localStorage.getItem('token');

  const jumpList = [
    // { icon: menu_icon, iconOn: menu_icon_on, label: 'Menu', url: '', type: 'menu' },
    { icon: casino, iconOn: casino_yellow, label: 'CASINO', url: '/21bits-originals', hideInPc: true },
    { icon: sport, iconOn: sport_yellow, label: 'SPORTS', url: '/game_sports', hideInPc: true },
    { icon: airdrop, iconOn: airdrop_yellow, label: 'AIRDROP', url: '/game_airdrop', hideInPc: false },
    { icon: trade, iconOn: trade_yellow, label: 'TRADE', url: '/game_upDown', hideInPc: true },
    { icon: raceOff, iconOn: raceOn, label: 'RACE', url: '/game_race', hideInPc: true },
    { icon: promotions, iconOn: promotions_yellow, label: 'PROMOTIONS', url: '/game_promotions', hideInPc: true },
    { icon: profile, iconOn: profile_yellow, label: 'PROFILE', url: '/game_profile', hideInPc: true, token: true }
  ];

  const handleClickItem = (item) => {
    if (item.label === 'Menu') {
      dispatch({ type: MOBILE_MENU_OPEN, mobileMenuOpen: !isMenuOpen });
      setItemLabel(item.label);
      return;
    }

    dispatch({ type: MOBILE_MENU_OPEN, mobileMenuOpen: false });
    setItemLabel(item.label);
    // if (!item.url) return;
    dispatch({ type: SIDEBAR_MENU, sidebarMenu: item.label });
    dispatch({ type: MENU_ITEM, menuItem: '' });

    window.localStorage.setItem('sidebarMenu', item.label);

    if (item.url) navigate(item.url);
    else {
      navigate('/home');
    }
  };

  const mouseEnter = (e, item) => {
    if (e.target?.src) return (e.target.src = item.iconOn);
    const img = e.target.getElementsByTagName('img');
    if (img && img.length > 0) img[0].src = item.iconOn;
  };

  const mouseLeave = (e, item) => {
    if (item.label === itemLabel) return;

    console.log(e.target);
    if (e.target?.src) return (e.target.src = item.icon);
    const img = e.target.getElementsByTagName('img');
    if (img && img.length > 0) img[0].src = item.icon;
  };
  function scrollLeft() {
    var container = scolRef.current;
    container.scrollBy({ left: 200, behavior: 'smooth' });
  }
  useEffect(() => {
    setItemLabel(currentSidebarMenu);
  }, [currentSidebarMenu]);

  useEffect(() => {
    if (!matchDownMd) {
      dispatch({ type: MOBILE_MENU_OPEN, mobileMenuOpen: false });
    }
  }, [matchDownMd]);
  return (
    <div className="jump-box-list">
      <div className="jump-box" ref={scolRef}>
        {jumpList.map((item) => (
          <Box
            key={item.label}
            className={classnames('jump-item', { 'item-active': itemLabel === item.label })}
            sx={{
              display: (!matchDownMd && item.hideInPc) || (item.token && !token) ? 'none !important' : 'flex',
              // display: 'none',
              flexDirection: matchDownMd ? 'column' : 'row',
              justifyContent: matchDownMd && 'space-around',
              marginTop: matchDownMd && '20px !important',
              fontSize: '13px',
              fontFamily: 'Geogrotesque Wide'
            }}
            onMouseEnter={(e) => mouseEnter(e, item)}
            onMouseLeave={(e) => mouseLeave(e, item)}
            onClick={() => {
              handleClickItem(item);
            }}
          >
            <img
              src={itemLabel === item.label ? item.iconOn : item.icon}
              alt=""
              style={{ width: '20px', marginRight: !matchDownMd && '8px' }}
            />
            {item.label}
          </Box>
        ))}
      </div>
      <div className="scollFlag" onClick={scrollLeft}>
        <ExpandMoreIcon
          sx={{
            width: '30px',
            height: '30px',
            // background: '#00000044',
            color: '#797979',
            borderRadius: '4px',
            transform: `rotate(${'270deg'})`
          }}
        />
      </div>
    </div>
  );
};

export default JumpSection;
