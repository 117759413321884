import { useState, useRef, useEffect } from 'react';
import { Box, ClickAwayListener, Popper, useMediaQuery } from '@mui/material';
import { getUserAssets, setWallet, activityProgres, claim, getUserCouponsTaskList, claimCouponsReward } from 'utils/api';
import { getUserAccount } from 'store/actions/userAction';
import { useTheme } from '@mui/material/styles';
import Transitions from 'ui-component/extended/Transitions';
import { gift, arrow_icon, arrow_icon_blue, wagered_cion, gitBlue, tips_blue, coupon_yellow } from 'static/images';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import StripeSlider from 'ui-component/stripeSlider';
import { toThousands } from 'utils/commonUtils';
import { useLocation } from 'react-router';
import BottomSelf from 'self-component/BottonSelf';
import BlackDialog from 'self-component/DialogSelf';

const WalletList = [{ id: 0, lable: '' }];

const GiftSection = () => {
  const theme = useTheme();
  const userInfor = useSelector((state) => state.customization.userInfor);
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { pathname } = useLocation();
  const [hideAccount, sethideAccount] = useState(false);
  const [account, setAccount] = useState({
    availableAmount: '0.00',
    promotionalBalance: '0.00',
    experienceBalance: '0.00',
    depositTargetWager: '0.00',
    depositWager: '0.00',
    depositCop: 0,
    walletType: 0
  });
  const [activeProgress, setActiveProgress] = useState({
    wagerPresent: 0,
    depositWager: '0.00',
    depositTargetWager: '0.00',
    isActivity: false
  });
  const [couponsProgres, setCouponsProgres] = useState({
    amount: '0',
    couponsTargetWager: '0',
    couponsWager: '0',
    isActivity: false,
    wagerPresent: 0
  });
  const [open, setOpen] = useState(false);
  const [giveData, setGiveData] = useState({});
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const matchDown420 = useMediaQuery(theme.breakpoints.down('420'));
  const matchDown600 = useMediaQuery(theme.breakpoints.down('600'));
  const usdtAccount = useSelector((state) => state.customization.usdtAccount);
  const [tips, setTips] = useState(false);

  useEffect(() => {
    if (pathname === '/third-game' || pathname === '/game_sports' || pathname === '/game_esports') {
      sethideAccount(true);
    } else {
      sethideAccount(false);
    }
  }, [pathname]);
  const handleToggle = async () => {
    setOpen((prevOpen) => !prevOpen);
    if (open) return;
    await getWalletInfo();
    await getActivityProgres();
    await getBonusData();
  };
  const getWalletInfo = async () => {
    try {
      const { data } = await getUserAssets();
      setAccount({
        availableAmount: toThousands(data.availableAmount),
        promotionalBalance: toThousands(data.promotionalBalance),
        experienceBalance: toThousands(data.experienceBalance),
        walletType: data.walletType
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('error', { autoHideDuration: 2000 });
    }
  };
  async function getActivityProgres() {
    try {
      const { data } = await activityProgres();
      setActiveProgress({
        ...data,
        wagerPresent: (Number(data.depositWager) / Number(data.depositTargetWager)) * 100,
        amount: toThousands(data.amount),
        depositTargetWager: toThousands(data.depositTargetWager),
        depositWager: toThousands(data.depositWager)
      });
    } catch (err) {
      console.log('activityProgres error', err);
    }
  }
  const getBonusData = async () => {
    try {
      const res = await getUserCouponsTaskList();
      if (res.code === 200) {
        const { data } = res;
        setCouponsProgres({
          ...data,
          amount: toThousands(data.amount),
          couponsTargetWager: toThousands(data.couponsTargetWager),
          couponsWager: toThousands(data.couponsWager),
          wagerPresent: ((data.couponsWager * 1) / (data.couponsTargetWager * 1)) * 100
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const changeWallet = async (id) => {
    try {
      const res = await setWallet({ walletType: id });
      setOpen(false);
      await getWalletInfo();
      await dispatch(getUserAccount());
    } catch (error) {}
  };
  const claimPromotionalBalance = async () => {
    try {
      const res = await claim();
      setOpen(false);
      await getWalletInfo();
      await dispatch(getUserAccount());
      enqueueSnackbar('Claim successfully.', { autoHideDuration: 2000, variant: 'success' });
    } catch (error) {}
  };
  const claimCoupons = async () => {
    try {
      const res = await claimCouponsReward();
      if (res.code === 200) {
        enqueueSnackbar('Claim success', { variant: 'success' });
        await getWalletInfo();
        await dispatch(getUserAccount());
        setOpen(false);
      }
    } catch (e) {
      enqueueSnackbar(e.msg, { variant: 'error' });
    }
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleEnter = (e) => {
    const img = e.target.querySelector('.arrow-icon');
    if (img) img.src = arrow_icon_blue;
  };
  const handleLeave = (e) => {
    const img = e.target.querySelector('.arrow-icon');
    if (img) img.src = arrow_icon;
  };

  return (
    <>
      <Box
        sx={{
          height: '30px',
          background: matchDown600 ? 'none' : '#0A0C13',
          borderRadius: '3px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          userSelect: 'none',
          '@media (min-width: 901px) and (max-width: 1100px)': {
            marginRight: '14px'
          }
        }}
        onClick={handleToggle}
        ref={anchorRef}
        onMouseEnter={(e) => {
          handleEnter(e);
        }}
        onMouseLeave={(e) => {
          handleLeave(e);
        }}
      >
        <div style={{ margin: '0 5px' }}>{hideAccount ? 'Playing' : `$${toThousands(Number(usdtAccount).toFixed(2)) || 0.0}`}</div>
        {!matchDown600 && (
          <div className="arrow-class">
            <img src={arrow_icon} alt="arrow_icon" style={{ transform: `rotate(${open ? '0deg' : '180deg'})` }} className="arrow-icon" />
          </div>
        )}
      </Box>
      <Popper
        placement={'bottom'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{zIndex:100}}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 100, 10]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions position={matchesXs ? 'top' : 'top-left'} type={'21Pops'} in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <div className="wallet-box">
                <div
                  className="claim-item"
                  style={{ border: account.walletType == 0 ? '2px solid #5096FF' : 'none', }}
                  onClick={() => changeWallet(0)}
                >
                  <div className="title">
                    <span>Primary Balance</span>
                    {account.walletType == 0 && <span className="active">Active</span>}
                  </div>
                  <div className="balane">
                    <img src={wagered_cion} width={19} alt="" srcset="" style={{ maxWidth: '19px', height: '19px', marginRight: '10px' }} />
                    <div>{account.availableAmount}</div>
                  </div>
                </div>
                {userInfor && userInfor.isKolInvited && (
                  <div
                    className="claim-item"
                    style={{ border: account.walletType == 1 ? '2px solid #5096FF' : 'none' }}
                    onClick={() => changeWallet(1)}
                  >
                    <div className="title">
                      <span style={{ display: 'flex' }}>
                        Promotional Balance
                        <img src={gitBlue} alt="" srcset="" style={{ marginLeft: '5px' }} />
                      </span>
                      {account.walletType == 1 && <span className="active">Active</span>}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div className="balane">
                        <img
                          src={wagered_cion}
                          width={19}
                          alt=""
                          srcset=""
                          style={{ maxWidth: '19px', height: '19px', marginRight: '10px' }}
                        />
                        <div>{account.promotionalBalance}</div>
                      </div>
                      {Number(activeProgress.wagerPresent) >= 100 && (
                        <BottomSelf
                          onClick={claimPromotionalBalance}
                          sx={{
                            minWidth: '40px',
                            height: '20px',
                            lineHeight: '20px',
                            fontSize: '14px',
                            borderRadius: '3px',
                            marginLeft: '10px'
                          }}
                        >
                          Claim
                        </BottomSelf>
                      )}
                    </div>

                    {activeProgress.depositTargetWager != 0 && (
                      <>
                        <StripeSlider percent={activeProgress.wagerPresent} />
                        <div className="wagerNum">
                          <div className="wargered">{`$${activeProgress.depositWager}`}</div>
                          <div className="targetWarge">{`$${activeProgress.depositTargetWager}`} Wagered</div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                <div
                  className="claim-item"
                  style={{ border: account.walletType == 2 ? '2px solid #5096FF' : 'none' }}
                  onClick={() => changeWallet(2)}
                >
                  <div className="title">
                    <span style={{ display: 'flex' }}>
                      Experience Balance
                      <img src={gitBlue} alt="" srcset="" style={{ marginLeft: '5px' }} />
                    </span>
                    {account.walletType == 2 && <span className="active">Active</span>}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div className="balane">
                      <img
                        src={wagered_cion}
                        width={19}
                        alt=""
                        srcset=""
                        style={{ maxWidth: '19px', height: '19px', marginRight: '10px' }}
                      />
                      <div>{account.experienceBalance}</div>
                    </div>
                    {Number(couponsProgres.wagerPresent) >= 100 && (
                      <BottomSelf
                        onClick={claimCoupons}
                        sx={{
                          minWidth: '40px',
                          height: '20px',
                          lineHeight: '20px',
                          fontSize: '14px',
                          borderRadius: '3px',
                          marginLeft: '10px'
                        }}
                      >
                        Claim
                      </BottomSelf>
                    )}
                  </div>

                  {couponsProgres.couponsTargetWager != 0 && (
                    <>
                      <StripeSlider percent={couponsProgres.wagerPresent} />
                      <div className="wagerNum">
                        <div className="wargered">{`$${couponsProgres.couponsWager}`}</div>
                        <div className="targetWarge">{`$${couponsProgres.couponsTargetWager}`} Wagered</div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: ' space-between'
                  }}
                >
                  <div style={{ textAlign: 'right', paddingRight: '5px' }}>Displaying currency in USD</div>
                  <div
                    style={{
                      color: '#8299C8',
                      fontSize: '14px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                    onClick={() => setTips(true)}
                  >
                    <span style={{ marginRight: '5px' }}>
                      <img src={tips_blue} alt="" srcset="" />
                    </span>
                    <span>Learn More</span>
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
      <BlackDialog
        open={tips}
        onClose={() => setTips(false)}
        width={540}
        title={<div style={{ display: 'flex', gap: '6px' }}>Wallet </div>}
      >
        <div
          className="vault-content"
          style={{
            '&>p': {
              marginTop: '10px'
            }
          }}
        >
          <p style={{ marginTop: '10px' }}>
            Primary Balance: Only the Primary Balance wallet can be used for TIPS and withdrawal operations.{' '}
          </p>
          <p style={{ marginTop: '10px' }}>
            Promotional Balance: Funds deposited for participating in the promotion, along with the Bonus, will be transferred to the
            Promotional Balance.
          </p>
          <p style={{ marginTop: '10px' }}>Once certain conditions are met, they can be transferred to the Primary Balance.</p>
        </div>
      </BlackDialog>
    </>
  );
};

export default GiftSection;
