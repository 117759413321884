import { createStore,applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import reducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //
const middle = [thunk]
const store = createStore(reducer, applyMiddleware(...middle));
const persister = 'Free';

export { store, persister };
