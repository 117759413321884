import React, { useRef, useEffect, useState } from 'react';
import './scss/codeBox.scss';

// interface CodeBoxProps {
//   /**
//    * @description
//    * @default 6
//    */
//   len?: number;
//   /**
//    * @description
//    */
//   onChange?: (code: string) => void;
//   /**
//    * @description
//    *
//    */
//   className?: string;
//   /**
//    * @description
//    * @default true
//    */
//   autoFocus?: boolean;
// }
export function CodeBox(props) {
  const { len = 6, onChange, className = '', autoFocus = true } = props;

  // const inputArr = new Array(len).fill('');
  const inputArr = Array.from({ length: len }, (_, i) => i + 1);
  const inputRefs = useRef([]);
  /**
   * @method getRef
   * @description
   */
  const getRef = (dom) => {
    if (inputRefs?.current?.length === len) {
      return;
    }
    inputRefs.current.push(dom);
  };
  /**
   * @method
   * @method
   * @param e
   * @param index
   */
  const onInputKeyDown = (e, index) => {
    switch (e.key) {
      case 'Backspace':
        if (index > 0 && !e.target.value) {
          const currentInputRef = inputRefs.current[index];
          currentInputRef.value = '';
          const prevInputRef = inputRefs.current[index - 1];
          prevInputRef.focus();
          // prevInputRef.select();
          e.preventDefault();
        }
        break;
      case 'Enter':
        if (props?.keyDownSubmit) {
          props?.keyDownSubmit();
        }
    }
  };
  /**
   * @method onInputValueChange
   * @description
   * @param index
   */
  const onInputValueChange = (index, e) => {
    if (index === 0 && e.target.value.length > 1) {
      const msg = e.target.value.trim();
      const msgArr = msg.split('');
      inputRefs.current?.forEach((ref, i) => {
        if (msgArr[i]) {
          ref.value = msgArr[i];
        }
        if (i < 5) inputRefs.current[i + 1].focus();
        else {
          inputRefs.current[5].focus();
        }
      });
      return onChange && onChange(msg);
    }

    let code = '';
    inputRefs.current?.forEach((ref) => {
      if (ref?.value) {
        code += ref?.value;
      } else {
        code += ' ';
      }
    });
    if (index > 0 && !e.target.value) {
      const prevInputRef = inputRefs.current[index - 1];
      prevInputRef.focus();
    }
    if (index < len - 1 && e.target.value) {
      const nextInputRef = inputRefs.current[index + 1];
      nextInputRef.focus();
    }
    onChange && onChange(code);
  };

  /**
   * @method getInputClassName
   * @description
   * @param index
   * @returns
   */
  const getInputClassName = (index) => {
    const currentInputRef = inputRefs.current[index];
    const value = currentInputRef?.value;
    const defaultClassName = 'code-box-input';
    return value ? defaultClassName + ' has-string' : defaultClassName;
  };
  useEffect(() => {
    if (autoFocus) {
      inputRefs?.current[0].focus();
    }
  }, [autoFocus]);
  return (
    <>
      <div className="CodeBoxCain">
        {inputArr.map((v, index) => {
          return (
            <input
              ref={getRef}
              maxLength={index === 0 ? 6 : 1}
              className={getInputClassName(index)}
              key={v}
              type="text"
              onFocus={() => {
                inputRefs.current[index].select();
              }}
              onKeyDown={(e) => {
                onInputKeyDown(e, index);
              }}
              onChange={(e) => {
                console.log(e);
                clearTimeout(timer);
                var timer = setTimeout(() => {
                  // 在这里处理输入事件
                  onInputValueChange(index, e);
                }, 100);
              }}
            />
          );
        })}
      </div>
    </>
  );
}
export default CodeBox;
