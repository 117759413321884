import { SET_TOKEN, SET_USER_INFOR, SET_ADDRESS, SET_USDT_ACCOUNT, TOTAL_BET_AMOUNT, SET_2FA_VERIFY,SET_WALLET_TYPE } from '../actions';
import { loginUseWallet, userInfo, logout, registerAccount, loginAccount, walletRegistration } from 'utils/api';
import { getUserCollection, addFavGame, removeFavGame } from 'utils/api/threeGame';
import { clearLocalInfor } from 'utils/commonUtils';
import { getUserAsset } from 'utils/api/userSet';

export const loginByWallet = (item) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const { address, signature } = item;
    let type = '';
    process.env.REACT_APP_ENV === 'test' ? (type = '1') : (type = '');
    loginUseWallet(address, signature, type)
      .then((res) => {
        window.localStorage.setItem('token', res.data);
        dispatch({
          type: SET_TOKEN,
          token: res.data
        });
        dispatch(getUserInfor({ address }));
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const loginByAccount = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let type = '';
    process.env.REACT_APP_ENV === 'test' ? (type = '1') : (type = '');
    loginAccount({ ...data, type })
      .then((res) => {
        window.localStorage.setItem('token', res.data);
        dispatch({
          type: SET_TOKEN,
          token: res.data
        });
        dispatch(getUserInfor());
        // loginSocket(res.data)
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function loginSocket(token) {
  console.log('token', token);
  starx.init({ host: '13.229.29.111', port: 8902, path: '' }, function (resp) {
    starx.request('Manager.Login', { token: token }, (res) => {
      window.localStorage.setItem('lv', res.lv || '');
      window.localStorage.setItem('isMod', res.typ || '');
    });
  });
}

export const registerByAccount = (data) => (dispatch) => {
  const code = window.localStorage.getItem('invit_code');
  return new Promise((resolve, reject) => {
    registerAccount({ ...data, invitationCode: code || '' })
      .then((res) => {
        dispatch(loginByAccount(data));
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const registerByWallet = (data) => () => {
  const code = window.localStorage.getItem('invit_code');
  return new Promise((resolve, reject) => {
    walletRegistration({ ...data, invitationCode: code || '' })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserAccount = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    getUserAsset()
      .then((res) => {
        window.localStorage.setItem('userAccount', JSON.stringify(res.data));
        const data = res.data;
        const availableAmount = data.walletType == 2 ? data?.experienceBalance  : data.walletType == 1 ? data.promotionalBalance : data.availableAmount
        window.localStorage.setItem('usdtAccount', availableAmount || 0.0);
        window.localStorage.setItem('totalBet', data?.totalBet || 0.0);
        window.localStorage.setItem('totalBetNum', data?.totalBetNum || 0.0);
        const depositNum = data?.depositNumber || 0;
        if (
          depositNum === 1 &&
          window.localStorage.getItem('firstDepositComplated') !== 'true' &&
          (window.localStorage.getItem('invit_code') === 'fbpro' || window.localStorage.getItem('invit_code') === 'fbpro2')
        ) {
          window.localStorage.setItem('firstDepositComplated', true);
          fbq('trackCustom', 'firstDeposit');
        }
        dispatch({
          type: SET_USDT_ACCOUNT,
          usdtAccount: availableAmount || 0.0
        });
        dispatch({
          type: TOTAL_BET_AMOUNT,
          usdtAccount: data?.totalBet || 0.0
        });
        dispatch({
          type: SET_WALLET_TYPE,
          usdtAccount: data?.walletType || 1
        });
        window.localStorage.setItem('WALLET_TYPE',data?.walletType)
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getUserInfor = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    userInfo()
      .then((res) => {
        window.localStorage.setItem('userInfo', JSON.stringify(res.data));
        dispatch({
          type: SET_USER_INFOR,
          userInfor: res.data
        });
        dispatch(getUserAccount());
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const logoutHand = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    logout()
      .then((res) => {
        dispatch({
          type: SET_ADDRESS,
          address: ''
        });
        dispatch({
          type: SET_TOKEN,
          token: ''
        });
        dispatch({
          type: SET_USER_INFOR,
          token: ''
        });
        clearLocalInfor();
        window.location.href = '/';
        resolve(res);
      })

      .catch((e) => {
        reject(e);
      });
  });
};

export const getFavoriteGameList = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    // { uid: customization.userInfor.id + '', name: '', provider: 0 }
    getUserCollection({
      uid: data.uid,
      name: '',
      provider: 0
    })
      .then((res) => {
        console.log(res, 'res');
        const data = res.data || [];
        window.localStorage.setItem('favorGame', JSON.stringify(data));
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const addFavoriteGameList = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    addFavGame(data)
      .then((res) => {
        dispatch(getFavoriteGameList(data));
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const romveFavoriteGameList = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    removeFavGame(data)
      .then((res) => {
        dispatch(getFavoriteGameList(data));
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
