import React, { useState, useEffect } from 'react';

import { Grid, Button, Dialog, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import Coupons from './Coupons';
import Referrals from './Referrals';
import ModelTabs from './../ModelTabs';
import { getUserAccount } from 'store/actions/userAction';
import { IS_CASHIER, IS_BUY_CRYPTO } from 'store/actions';
import './scss/cashierModel.scss';

import { deposits, deposits_yellow, withdraw, withdraw_yellow, coupon, coupon_yellow, Vector, Vector_yellow } from 'static/images';

import Transitions from 'ui-component/extended/Transitions';
import { maxHeight } from '@mui/system';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Transitions style={{}} position={'top'} type={'21Pops'} {...props} />
    </div>
  );
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '1030px',
    width: '1030px',
    border: '1px #152D43 solid',
    backgroundColor: '#151E2F',
    borderRadius: '8px',
    height: '99%',
    // margin: '0'
    '@media (max-width:1030px)': {
      margin: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%'
    },
    '@media (max-width:800px)': {
      margin: 0,
      width: '100%',
      height: '100%',
      maxHeight: '100%'
    }
  },

  '& .MuiDialogContent-root': {
    padding: '16px 52px 48px 52px',
    '@media (max-width:950px)': {
      padding: '16px 26px',
      maxHeight: '85vh'
    }
  }
  // '& .Mui-selected': {
  //   boxSizing: 'border-box',
  //   color: '#FFE619 !important',
  //   backgroundColor: '#0D131D',
  //   fontSize: '15px',
  //   borderRadius: '13px'
  // }
}));

export default function CashierModel() {
  const isCashier = useSelector((state) => state.customization.isCashier);
  const isCoupons = useSelector((state) => state.customization.isCoupons);
  const [tabValue, setTabValue] = useState('deposit');
  const dispatch = useDispatch();

  const tabOptions = [
    { index: 1, label: 'DEPOSIT', value: 'deposit', icon: deposits, iconOn: deposits_yellow },
    { index: 2, label: 'WITHDRAW', value: 'withdraw', icon: withdraw, iconOn: withdraw_yellow },
    { index: 3, label: 'COUPONS', value: 'coupons', icon: coupon, iconOn: coupon_yellow },
    { index: 4, label: 'REFERRALS', value: 'referrals', icon: Vector, iconOn: Vector_yellow }
  ];

  const handleChangeTab = (val) => {
    setTabValue(val);
  };

  const handleColose = async () => {
    dispatch(getUserAccount());
    dispatch({ type: IS_CASHIER, isCashier: false });
    dispatch({ type: IS_BUY_CRYPTO, isBuyCrypto: false });
  };

  useEffect(() => {
    if (isCashier) {
      isCoupons ? setTabValue('coupons') : setTabValue('deposit');
    }
  }, [isCashier]);

  return (
    <div>
      <BootstrapDialog open={isCashier} TransitionComponent={Transition} onClose={handleColose} aria-describedby="alert-dialog-wallet">
        <Box sx={{ position: 'relative', background: '#152D43', height: '50px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ maxWidth: '85vw', overflow: 'auto', marginRight: '45px' }}>
            <ModelTabs tabValue={tabValue} tabs={tabOptions} changeTab={handleChangeTab} />
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleColose}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#576788',
              zIndex: 2,
              transition: 'all 0.2s ease-in',
              '&:hover': {
                color: '#5096FF'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent>
          {/* <Box className="tab-box">
            {tabOptions.map((tab) => (
              <Box
                key={tab.index}
                onClick={() => handleChangeTab(tab.value)}
                className={tab.value === tabValue ? 'tab-item tab-item-active' : 'tab-item'}
              >
                {tab.label}
              </Box>
            ))}
          </Box> */}

          <Box>
            {tabValue === 'deposit' && <Deposit closeModel={handleColose} />}
            {tabValue === 'withdraw' && <Withdraw closeModel={handleColose} />}
            {tabValue === 'coupons' && <Coupons />}
            {tabValue === 'referrals' && <Referrals />}
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
